import {
  API_PATH_CATEGORIES as CATEGORIES,
  API_PARAM_CATEGORY_ID as CATEGORY_ID,
  API_PATH_REPORTS as REPORTS,
  API_PARAM_REPORT_ID as REPORT_ID,
  API_PATH_SFR as SFR,
  API_PATH_SFR_CONFIG as SFR_CONFIG,
  API_PATH_STX as STX,
  API_PATH_SURVEYS as SURVEYS,
  API_PARAM_SURVEY_PERIOD_ID as SURVEY_PERIOD_ID
} from '@ps-monorepo/api-constants';

// Some environmental config constants
export const ENV = { baseUrl: '/' };

// App Routes
export const RTE_DASHBOARD = '/';
export const RTE_STX = `/${STX}`;
export const RTE_SFR = `/${SFR}`;
export const RTE_SFR_CONFIG = `/${SFR}/${SFR_CONFIG}`;
export const RTE_SFR_SUBMISSION_CAT_SURVEY_PRD = `/${SFR}/${CATEGORIES}/:${CATEGORY_ID}/${SURVEYS}/:${SURVEY_PERIOD_ID}`;
export const RTE_SFR_SUBMISSION_CAT_SURVEY_PRD_REPORTS = `/${SFR}/${CATEGORIES}/:${CATEGORY_ID}/${SURVEYS}/:${SURVEY_PERIOD_ID}/${REPORTS}`;
export const RTE_SFR_SUBMISSION_CAT_SURVEY_PRD_REPORT = `/${SFR}/${CATEGORIES}/:${CATEGORY_ID}/${SURVEYS}/:${SURVEY_PERIOD_ID}/${REPORTS}/:${REPORT_ID}`;
export const RTE_SFR_PRIOR_YEAR_SUBMISSIONS = '/sfr-prior-year-submissions';
export const RTE_SECONDARY_REPORTING = '/secondary-reporting';

// Demo/Dev Routes
export const RTE_DEMO_SSE = '/demo-sse';
export const RTE_DEMO_FASTER = '/demo-faster';
export const RTE_DEMO_TYPE_AHEAD_WIDGET = '/demo-type-ahead-widget';

// PDS Class Constants
export const PDS_IS_ACTIVE = 'pds-is-active';
export const PDS_TABS_PANEL = 'pds-tabs-panel';
