import {
  SFR_SUBMISSION_IN_PROGRESS,
  SFR_SUBMISSION_NO_PREVIOUS_RUNS, SFR_SUBMISSION_NO_SCHEDULE, SFR_SUBMISSION_SCHEDULE_EXPIRED
} from '@ps-monorepo/api-constants';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Time as CommonUtilsTime } from 'common-utils/interfaces';
import { Time } from '../models';

/**
 * Promisified setTimeout where time parameter is in milliseconds
 *
 * @param timeInMilliseconds
 */
export function delay(timeInMilliseconds: number) {
  return new Promise((resolve) => { setTimeout(resolve, timeInMilliseconds); });
}

/**
 * Promisified setTimeout where time parameter is in seconds
 *
 * @param timeInSeconds
 */
export function delaySec(timeInSeconds: number) {
  return new Promise((resolve) => { setTimeout(resolve, timeInSeconds * 1000); });
}

/**
 * Generate a date object from given string expected to be in format YYYY*MM*DD, where the
 * delimiter placeholder * can either be '-' or '/' or a custom string.
 *
 * Note: this also seems to work with ISO date strings (YYYY-MM-DDTHH:mm:ss.sssZ). The dayStr value
 *       set from the split function ends up looking like DDTHH:mm:ss.sssZ, but the parseInt()
 *       function seems to get rid of THH:mm:ss.sssZ, taking only DD to be converted.
 *
 * @param dateAsStr
 * @param delim
 */
export function dateFromDelimStringYYYYMMDD(dateAsStr: string, delim?: string): Date | undefined {
  // Blank date translates to undefined
  if (dateAsStr == null || dateAsStr.trim().length === 0) return undefined;
  let delimToUse = delim;
  // If delimiter is not otherwise specified
  if (delimToUse == null) {
    // Check for hyphen delimiter
    if (dateAsStr.indexOf('-') >= 0) delimToUse = '-';
    // Check for forward slash delimiter
    else if (dateAsStr.indexOf('/') >= 0) delimToUse = '/';
    // Error on unsupported unknown delimiter
    else throw Error(`Unsupported delimiter type in date string: ${dateAsStr}`);
  }
  // Split date on delimiter
  const [yearStr, monthStr, dayStr] = dateAsStr.split(delimToUse);
  // Convert to number (base 10 obviously)
  const year = Number.parseInt(yearStr, 10);
  const month = Number.parseInt(monthStr, 10);
  const day = Number.parseInt(dayStr, 10);
  // Check for valid numbers
  if (Number.isNaN(year)) throw new Error('Year is not a valid integer');
  if (Number.isNaN(month)) throw new Error('Month is not a valid integer');
  if (Number.isNaN(day)) throw new Error('Day is not a valid integer');
  // Return date object (zero out hours/minutes/seconds/ms as it's not relevant)
  return new Date(year, month - 1, day, 0, 0, 0, 0);
}

/**
 * Convert value to Date object if it is a string, otherwise just return as is
 *
 * @param dateValue
 */
export function convertToDate(dateValue: string | Date | null | undefined): Date | null {
  if (dateValue) {
    if (typeof dateValue === 'string') {
      return new Date(dateValue);
    }
    if (dateValue instanceof Date) {
      return dateValue;
    }
    throw new Error(`Unsupported type for conversion to date: ${typeof dateValue}`);
  }
  return null;
}

/**
 * Type guard specific to CommonUtilsTime
 *
 * @param obj
 */
export function isCommonUtilsTime(obj: any): obj is CommonUtilsTime {
  return 'to24HourTime' in obj
    && 'getHours' in obj
    && 'setHours' in obj
    && 'setMinutes' in obj
    && 'getMinutes' in obj
    && 'getTime' in obj
    && 'set' in obj;
}

/**
 * Convert value to Time object if it is a string or date, otherwise just return as is
 *
 * @param timeValue
 */
export function convertToTime(
  timeValue: string | Date | null | undefined | CommonUtilsTime | Time
): CommonUtilsTime | null {
  if (timeValue) {
    if (typeof timeValue === 'string') {
      // Check for a 24-hour/60-minute time format (23:30 is 11:30pm)
      if (/^\s*[0-9]{1,2}\s*:{1}\s*[0-9]{1,2}\s*$/.test(timeValue)) {
        // Split hours and minutes
        const [hours24, minutes60] = timeValue.split(':');
        // Check for appropriate hours/minutes range
        if (+hours24 < 24 && +minutes60 < 60) {
          // Hours/minutes range is okay, create Time object
          const d = new Date();
          d.setHours(+hours24);
          d.setMinutes(+minutes60);
          // Return time
          return new Time(d);
        }
      }
      // At this point, it does not look like value is a 24-hour/60-minute time format, so just
      // attempt to interpret as a full date/time string
      return new Time(new Date(timeValue));
    }
    if (timeValue instanceof Date) {
      return new Time(timeValue);
    }
    if (timeValue instanceof Time) {
      return timeValue;
    }
    // Note: CommonUtilsTime is interface/type from common-utils that is implemented by our local
    //       Time model
    if (isCommonUtilsTime(timeValue)) {
      return timeValue;
    }
    throw new Error(`Unsupported type for conversion to time: ${typeof timeValue}`);
  }
  return null;
}

/**
 * Returns the portion of an ISO date/time string that represents the date,
 * stripping off the time component.
 *
 * @param dateString
 * @returns
 */
export function parseDatePartFromISOString(dateString: string): string {
  return dateString.split('T')[0];
}

/**
 * Format date param to locale date string. If date param is a string, it is expected to be in a
 * form that can be converted into a Date object. For example, any of the following formats can
 * be converted into a Date object:
 *
 *   "2021-07-30T00:00:00.000Z"      (ISO date/time)
 *   "2021-07-30"                    (ISO date)
 *   "Fri, 30 Jul 2021 00:00:00 GMT" (UTC date/time)
 *   "Fri, 30 Jul 2021"              (UTC date)
 *   "07/30/2021 12:00:00 AM"        (Locale date/time)
 *   "07/30/2021"                    (Locale date)
 */
export function toLocaleDateString(date: string | Date): string {
  let d: Date;
  if (date == null) {
    return '';
  }
  if (date instanceof Date) {
    d = date;
  } else if (typeof date === 'string') {
    d = new Date(date);
  }
  return d.toLocaleDateString();
}

/**
 * Format start/end date params into a date range string, but only if both start and end date are
 * specified. Otherwise, returns an empty string.
 *
 * @param startDate
 * @param endDate
 */
export function toLocalDateRangeString(startDate: string | Date, endDate: string | Date): string {
  if (startDate && endDate) {
    return `${toLocaleDateString(startDate)} — ${toLocaleDateString(endDate)}`;
  }
  return '';
}

/**
 * Format date param to locale date and time string. If date param is a string, it is expected to
 * be in a form that can be converted into a Date object. For example, any of the following formats
 * can be converted into a Date object:
 *
 *   "2021-07-30T00:00:00.000Z"      (ISO date/time)
 *   "2021-07-30"                    (ISO date)
 *   "Fri, 30 Jul 2021 00:00:00 GMT" (UTC date/time)
 *   "Fri, 30 Jul 2021"              (UTC date)
 *   "07/30/2021 12:00:00 AM"        (Locale date/time)
 *   "07/30/2021"                    (Locale date)
 */
export function toLocaleDateTimeString(date: string | Date): string {
  let d: Date;
  if (date == null) {
    return '';
  }
  if (date instanceof Date) {
    d = date;
  } else if (typeof date === 'string') {
    d = new Date(date);
  }
  return `${d.toLocaleDateString()} ${d.toLocaleTimeString()}`;
}

/**
 * Indicates whether dates are equal, ignoring any time/time zone component.
 *
 * @param date1
 * @param date2
 */
export function areDatesEqual(date1: Date, date2: Date): boolean {
  return date1.getFullYear() === date2.getFullYear()
    && date1.getMonth() === date2.getMonth()
    && date1.getDate() === date2.getDate();
}

/**
 * Formats a date into a string of the form YYYY-MM-DD
 *
 * @param date
 */
export function dateAsStringYYYYMMDD(date: Date): string {
  if (date) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Adding 1 because months are zero-indexed
    const day = date.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
  }
  return '';
}

/**
 * Several string values are stored in DDB in all caps,
 * but we wish to display them with only the first letter capitalized.
 */
export function convertToInitialCase(string: string): string {
  return string
    ? string.substring(0, 1).concat(string.toLowerCase().substring(1, string.length))
    : '';
}

/**
 * This was introduced to handle district names, which are stored as all caps in
 * the requests table. It may become unnecessary if/when we pull the names from
 * the institutions table instead.
 *
 * @param string
 * @returns
 */
export function convertToInitialCaseMultiWord(string: string): string {
  const words:string[] = string.split(' ');
  let result:string = '';
  for (let i = 0; i < words.length; i += 1) {
    if (i > 0) {
      result += ' ';
    }
    result += convertToInitialCase(words[i]);
  }
  return result;
}

/**
 * Institution Text is in the format of 'School Name (041234)', but only the school name is
 * required. The code in the parenthesis is removed and only the school name is returned.
 * @param text
 */
export function convertInstitutionTextToName(
  text: string
) {
  const parenStart = text.indexOf('(');
  // No parenthesis found
  if (parenStart === -1) return text;
  return text.slice(0, parenStart).trim();
}

/**
 * Returns the date in mm/dd/yyyy format
 * @param inputDate
 */
export function convertDateToString(dateValue: string | Date | null | undefined): string | null {
  function pad(s) {
    return (s < 10) ? `0${s}` : s;
  }
  if (dateValue) {
    const d = new Date(dateValue);
    return [pad(d.getMonth() + 1), pad(d.getDate()), d.getFullYear()].join('/');
  }
  return null;
}

export function getUTCDate(value: Date) {
  if (!value) return value;
  return new Date(
    Date.UTC(
      value.getFullYear(),
      value.getMonth(),
      value.getDate()
    )
  );
}

/**
 * Returns the list with the item moved from startIndex position to the endIndex position
 * @param list
 * @param startIndex
 * @param endIndex
 */
export function reorderList(list, startIndex, endIndex) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
}

/**
 * Format last run value of SFR submission status
 *
 * @param lastRun
 */
export function formatLastRunValue(lastRun: string | Date): string {
  // No previous runs (includes null and empty values)
  if (
    lastRun == null
    || (lastRun as string).trim().length === 0
    || lastRun === SFR_SUBMISSION_NO_PREVIOUS_RUNS
  ) {
    return 'No previous runs';
  }
  // In progress
  if (lastRun === SFR_SUBMISSION_IN_PROGRESS) {
    return 'In progress';
  }
  // Previous run date/time provided
  return toLocaleDateTimeString(lastRun);
}

/**
 * Format next scheduled run value of SFR submission status
 *
 * @param nextScheduledRun
 */
export function formatNextScheduledRunValue(nextScheduledRun: string | Date): string {
  // No future run scheduled (includes null and empty values)
  if (
    nextScheduledRun == null
    || (nextScheduledRun as string).trim().length === 0
    || nextScheduledRun === SFR_SUBMISSION_NO_SCHEDULE
  ) {
    return 'No schedule';
  }
  // Schedule expired
  if (nextScheduledRun === SFR_SUBMISSION_SCHEDULE_EXPIRED) {
    return 'Schedule expired';
  }
  // Next scheduled run date/time provided
  return toLocaleDateTimeString(nextScheduledRun);
}
